import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div>
            <div className="sub_heading" style={{ justifyContent: 'center', marginLeft: '-10%'}}>
                <h1>Privacy Policy</h1>
            </div>
            <div className="main_container">
                <div>

                    <p className="about_us_data x_heading">
                        TSREDCO respects the privacy of everyone who visits this website and is committed to maintain the trust and confidence of visitors of its website (https://tsredco.telangana.gov.in). As a general rule, this website does not collect Personal Information about user who visits the site. Any person can generally visit the site without revealing Personal Information, unless he / she choose to provide such information. TSREDCO will not sell, rent, publish or trade any user’s personal information to any individual or agency/organisation under any circumstances.
                    </p>
                    <p className="about_us_data x_heading">
                        While TSREDCO assure users that the company will use all reasonable measures to protect the security of user’s data, users may note that it is not possible for the TSREDCO to completely guarantee that user data will be immune from malicious attack or compromise and no protection is full proof. As such, the users should understand that their transmission of personal data is always at their own risk and users may ensure that no sensitive information is disclosed and take steps to protect information submitted on the site, in their own interest
                    </p>
                    <p className="about_us_data x_heading">
                        All users are requested to note that applicable law of India and exclusive jurisdiction courts in Hyderabad is applicable, regardless of the country/place from which their data originates.
                    </p>
                    <p className="about_us_data x_heading">
                        TSREDCO may process the personal information of user;
                    </p>
                    <p className="about_us_data">
                        · As and when user uses services of TSREDCO
                        <br />
                        · The processing is in TSREDCO’s legitimate interests, and it’s not overridden by user’s rights
                        <br />
                        · For payment processing purposes
                        <br />
                        · To comply with the law of the Land
                    </p>
                    <p className="about_us_data x_heading">
                        TSREDCO may disclose personal information, usage data, message data, and any other information about user, if TSREDCO deem that it is reasonably necessary to: (a) satisfy any applicable law, regulation, legal process, court orders, statutory Audits, government requests, protect against harm to it.
                    </p>
                    <p className="about_us_data x_heading">
                        The authorised controller of this website is Telangana State Renewable Energy Development Corporation Limited (TSREDCO) having its registered office at Regd. Office Cum Corporate Office: D.No. 6-2-910, Visvesvaraya Bhavan,The Institution of Engineers Building, Khairatabad, Hyderabad - 500 004. Telangana State, India.The information contained in this website has been prepared solely for the purpose of providing information about TSREDCO in public interest.
                    </p>
                    <p className="about_us_data x_heading">
                        Users may access or/and download the information or material located on https://tsredco.telangana.gov.in only for personal and non-commercial use. The unauthorized use/ illegal use of the material available on the website is strictly prohibited.
                    </p>
                    <p className="about_us_data x_heading">
                        TSREDCO may track domain names, IP addresses and browser types of people who visit this website. This information may be used to track aggregate traffic patterns and preparations of internal audit reports. Such information is not correlated with any personal information and it is not shared with anyone or anywhere.
                    </p>
                    <p className="about_us_data x_heading">
                        This site may have some links on the site which may lead to servers maintained by third parties over whom TSREDCO has no control. TSREDCO has no responsibility or liability of any sort for any of the material contained on third party servers.
                    </p>
                    <p className="about_us_data x_heading">
                        TSREDCO’s web portal may use some social media networks such as Facebook, Youtube & Twitter etc. to share our information/videos for better convenience of the commuters and
                        general public at large. TSREDCO do not claim any responsibility nor shall any claim stand against TSREDCO if any error/inconvenience occurs while browsing it or any objection put on it by these site hosting organisation/agencies at any time.
                    </p>
                    <p className="about_us_data x_heading">
                        TSREDCO without any prior permission do not permit anyone to load/hyperlink any page of the website to any other website. TSREDCO also do not permit any app/blog or any social media pages to be hyperlinked to TSREDCO’s website or any page of the website.
                    </p>
                    <p className="about_us_data x_heading">
                        By accessing this website the user agrees that TSREDCO will not be liable for any direct or indirect loss, of any nature whatsoever, arising from the use or inability to use website and from the material contained in this website or in any link thereof. The use of the website or any incidental issue that may arise shall be strictly governed by the laws in force in India and by using this website the user submits himself/herself to the exclusively jurisdiction of the courts in Hyderabad/Telangana.
                    </p>
                    <p className="about_us_data x_heading">
                        The TSREDCO website may use cookies to allow the user to toggle between Hindi and English or for other purposes. The same is used to enhance the user’s experience and not to collect any personal information. The copyright of the material contained in this website exclusively belongs to and is solely vested with TSREDCO. The access to this website does not license the user to reproduce or transmit or store anywhere or disseminate the contents of any part thereof in any manner.
                    </p>
                    <p className="about_us_data x_heading">
                        TSREDCO reserves its rights to change or amend its privacy policy as per the management policy norms or Government of Telangana guidelines from time to time.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
