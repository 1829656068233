import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import React, { useEffect, useState } from 'react'
import Select from '../../../storybook/Select/Select'
import admin_logo from '../../assets/images/admin_logo.svg'
import convergence_logo from '../../assets/images/convergence_logo.png'
import govt_logo from '../../assets/images/govt.svg'
import logo_3 from '../../assets/images/logo_3.svg'
import my_ev from '../../assets/images/tsredco_logo.svg'
import dlogo from '../../assets/images/dlogo.jpeg'
import cm_icon from '../../assets/images/CM_icon.svg'
import geda_logo from '../../assets/images/GEDA_LOGO.jpg'
import Login from '../../auth/Login'
import '../Catalogue.css';
import { useSelector, useDispatch } from 'react-redux'
import { customerLogout } from '../../../redux/actions'
import { useHistory } from 'react-router'
import Avatar from '@material-ui/core/Avatar';
import { ucfirst } from '../../../helper'



const Header = () => {
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const isAuth = useSelector(state => state.authReducer.token);
    const loginData = useSelector(state => state.authReducer.login_response);
    const loading = useSelector(state => state.productReducer.product_loading)
    const logo_response = useSelector(state => state.productReducer.response)

    const [stateId, setStateId] = useState(null)

    const handleClickOpen = () => {
        !isAuth && setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        dispatch(customerLogout())
        history.push('/')
    };

    const handletoHome = () => {
        history.push('/')
    };

    useEffect(() => {
        isAuth && handleClose()
    }, [isAuth])


    useEffect(() => {
        let id = localStorage.getItem('x_auth_state_id');
        setStateId(id)
    }, [loading])

    return (
        <>
            <div style={{ position: 'fixed', width: '100%', backgroundColor: '#fff', zIndex: '6', height: '16%' }}>
                <div className="catalogue_header_wrapper">
                    <div>
                        <img src={cm_icon} style={{ paddingTop: 10 }} />
                    </div>
                    <div>

                        <img src={govt_logo} className="logo main_logo" alt="convergence" height="100" onClick={handletoHome} style={{ paddingTop: 10, paddingRight: 10, cursor: 'pointer' }} />
                    </div>
                    <div className="product_wrapper">
                        <span className='main_heading'>Telangana State Renewable Energy Development Corporation Ltd., (TSREDCO)</span>
                        <span className='main_heading'>తెలంగాణ రాష్ట్ర పునరుద్ధరణీయ ఇంధన వనరుల అభివృద్ధి సంస్థ లిమిటెడ్</span>
                        <span className='main_heading_state'>(A State Government Company)</span>

                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', padding: 5 }}>
                        <img src={my_ev} alt="myev" className="my_ev_logo" height={100} width={100} style={{ marginRight: 15, cursor: 'pointer', padding: 5 }} />

                        {/* {
                            logo_response?.states?.map((item) => (
                                item.logoURL !== null && item.id == stateId && <img src={item.logoURL} alt="logo" height="65" className="emc_logo" style={{ padding: 5 }} />
                            ))



                        } */}


                        <div className="center">
                            <div>
                                <h4 className="xs_heading">{loginData.name && ucfirst(loginData.name.split(' ')[0])}</h4>
                                {
                                    isAuth &&
                                    <div style={{ textAlign: 'end' }}>
                                        <span style={{ cursor: 'pointer', color: '#064A76' }} className="xs_heading" onClick={handleLogout} >Logout</span>
                                    </div>
                                }


                            </div>
                            {
                                isAuth ? <Avatar style={{ color: 'white', backgroundColor: "#FF914D", marginRight: 15 }} className="avatar">
                                    {loginData.name ? ucfirst(loginData.name[0]) : ''}
                                </Avatar>
                                    :
                                    <img src={admin_logo} alt="admin" height="30" style={{ paddingRight: 17 }} onClick={handleClickOpen} />
                            }

                        </div>



                    </div>

                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
            >
                <DialogContent style={{ padding: 0 }}>
                    <Login modal="true" />
                </DialogContent>

            </Dialog>

        </>
    )
}

export default Header